import { Box, Paper, TableContainer, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { MdOutlineCancel, MdDoneAll } from "react-icons/md";
import { DataGrid } from "@mui/x-data-grid";
import {
  useDeleteTransactionMutation,
  useSearchTransactionsQuery,
  useRetriggerTransactionMutation,
  useSearchPendingTransactionsQuery,
} from "../services/TransactionService";
import CustomAlertDialog from "../components/common/CustomAlertDialog";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";
import CustomToolbar from "../components/common/CustomToolbar";
import { TransactionsPaymentProcessingTable } from "../data/TransactionsPaymentProcessingTable";

const TransactionProcessingPayment = () => {
  const dataGridService = new DataGridService("Transaction");
  const [filter, setFilter] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const { data, isLoading } = useSearchPendingTransactionsQuery({
    ...filter,
    status: "PAYMENT_PROCESSING",
  });
  const [deleteTransaction] = useDeleteTransactionMutation();
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [settleStatus, setSettleStatus] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [UpdateTransactionProcess] = useRetriggerTransactionMutation();

  const [sortModel, setSortModel] = useState([
    { field: "created", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const [isClicked, setIsClicked] = useState(false);

  const siteReference = process.env.REACT_APP_SITE_REFERENCE;
  console.log("siteReference", siteReference);

  const handleRetrigger = async (transactionReference) => {
    if (selectedTransaction) {
      const retriggerPayload = {
        orderId: selectedTransaction?.orderId,
        transactionId: selectedTransaction?.id,
        siteReference: siteReference,
        settleStatus: settleStatus,
        transactionReference: transactionReference,
        errorCode: errorCode,
      };

      try {
        setIsClicked(true);
        const response = await UpdateTransactionProcess(retriggerPayload);
        if (response.code === 0) {
          SuccessToast(
            `Payment ${
              settleStatus === "2" ? "Canceled" : "Processed"
            } successfully`
          );
          setSettleStatus("");
          setOpen(false);
          setOpenCancel(false);
          setIsClicked(false);
        } else {
          ErrorToast("Failed to Update");
          setOpen(false);
          setOpenCancel(false);
          setIsClicked(false);
        }
      } catch (error) {
        ErrorToast("Failed to update transaction");
        setOpen(false);
        setOpenCancel(false);
        setIsClicked(false);
      }
    }
  };

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };

  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };

  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
    }));
  };

  const handleCompleteAction = (row) => {
    setOpen(true);
    setErrorCode("0");
    setSelectedTransaction(row);
    setSettleStatus("0");
  };

  const handleCancelAction = (row) => {
    setOpenCancel(true);
    setErrorCode("1");
    setSelectedTransaction(row);
    setSettleStatus("2");
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCancel(false);
  };

  const columns = TransactionsPaymentProcessingTable(
    handleCancelAction,
    handleCompleteAction
  );

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Pending Payment</Typography>
        </Box>
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          title={"Complete Transaction"}
          subTitle={`Are you sure you want to complete transaction: ${selectedTransaction?.id}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<MdDoneAll size={30} />}
          fullWidth={true}
          modalType={"complete"}
          contentForSelectedRow={selectedTransaction}
          handleRetrigger={handleRetrigger}
          isClicked={isClicked}
        />
        <CustomAlertDialog
          open={openCancel}
          handleClose={handleClose}
          title={"Cancel Transaction"}
          subTitle={`Are you sure you want to cancel transaction: ${selectedTransaction?.id}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<MdOutlineCancel size={30} />}
          fullWidth={true}
          contentForSelectedRow={selectedTransaction}
          modalType={"cancel"}
          handleRetrigger={handleRetrigger} // Pass handleRetrigger function
          isClicked={isClicked}
        />
        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              filterMode="server"
              paginationMode="server"
              sortingMode="server"
              loading={isLoading}
              rowCount={data?.data?.totalElements ?? 0}
              columns={columns}
              rows={data?.data?.data ?? []}
              getRowId={(row) => row.id}
              disableColumnSelector
              disableDensitySelector
              components={{
                Toolbar: () => <CustomToolbar handleExport={() => {}} />,
              }}
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationModelChange}
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default TransactionProcessingPayment;
