import { Print } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import ReactToPrint from "react-to-print";

const BannerModal = ({
  open,
  handleClose,
  banner,
  type,
  handleFormChange,
  handleSubmitCountry,
}) => {
  const filter = queryString.stringify({ size: 10000 });
  const printRef = React.useRef();
  return (
    <div>
      <div>
        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          sx={{ mx: "auto", my: "0px" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle> {`${type} Banner`}</DialogTitle>
            {type === "VIEW" && (
              <ReactToPrint
                trigger={() => (
                  <Button>
                    <Print />
                  </Button>
                )}
                content={() => printRef.current}
              />
            )}
          </Box>
          <DialogContent>
            {type === "VIEW" ? (
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  Id:{" "}
                  <span style={{ opacity: 0.7 }}>{banner?.id ?? "n/a"}</span>
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  Name:{" "}
                  <span style={{ opacity: 0.7 }}>{banner?.name ?? "n/a"}</span>
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  Path:{" "}
                  <span style={{ opacity: 0.7 }}>{banner?.path ?? "n/a"}</span>
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  Created:{" "}
                  <span style={{ opacity: 0.7 }}>
                    {banner?.created
                      ? moment(banner?.createdOn).format("LLLL")
                      : "n/a"}
                  </span>
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={1} padding={1}>
                <Grid item md={6} xs={12} sm={12}>
                  <TextField
                    label="Name"
                    name="name"
                    variant="outlined"
                    value={banner?.name}
                    onChange={handleFormChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12} sm={12}>
                  <TextField
                    label="path"
                    name="path"
                    variant="outlined"
                    value={banner?.isoCode}
                    onChange={handleFormChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            {type !== "VIEW" ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button color="error" onClick={handleClose} variant="contained">
                  CANCEL
                </Button>
                <Button onClick={handleSubmitCountry} variant="contained">
                  {type}
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button onClick={handleClose} variant="contained">
                  OK
                </Button>
              </Box>
            )}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default BannerModal;
