import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fileUploadUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const fileUploadApi = createApi({
  reducerPath: "fileUploadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: fileUploadUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  tagTypes: ["FileUpload"],
  endpoints: (builder) => ({
    bulkUploadFiles: builder.mutation({
      query: (formData) => ({
        url: "upload",
        method: "POST",
        body: formData, // This should be the actual FormData instance
      }),
      providesTags: ["FileUpload"],
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const { useBulkUploadFilesMutation } = fileUploadApi;
