import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { transactionsUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: transactionsUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Transactions"],
  endpoints: (builder) => ({
    getAllTransactions: builder.query({
      query: (filterTransactionRequest) => ({
        url: `/transactions?${filterTransactionRequest}`,
        method: "GET",
      }),
      providesTags: ["Transactions"],
    }),

    getTransaction: builder.query({
      query: (id) => ({
        url: `/transactions/${id}`,
        method: "GET",
      }),
      providesTags: ["Transactions"],
    }),

    searchTransactions: builder.query({
      query: (filterTransactionRequest) => ({
        url: `/transactions?${queryString.stringify(
          filterTransactionRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Transactions"],
    }),
    updateTransaction: builder.mutation({
      query: (updateTransactionRequest) => ({
        url: "/transactions",
        method: "PUT",
        body: updateTransactionRequest,
      }),
      invalidatesTags: ["Transactions"],
    }),
    updateTransactionProcess: builder.mutation({
      query: (updateTransactionRequest) => ({
        url: "/transactions",
        method: "PUT",
        body: updateTransactionRequest,
      }),
      invalidatesTags: ["Transactions"],
    }),
    createTransaction: builder.mutation({
      query: (addTransactionRequest) => ({
        url: "/transactions",
        method: "POST",
        body: addTransactionRequest,
      }),
      invalidatesTags: ["Transactions"],
    }),
    deleteTransaction: builder.mutation({
      query: (id) => ({
        url: `/transactions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Transactions"],
    }),
    getLatestTransactions: builder.query({
      query: () => ({
        url: "/transactions?sortOrder=DESC",
        method: "GET",
      }),
      providesTags: ["Transactions"],
      transformResponse: (response) => response.data,
    }),
    getOlderTransactions: builder.query({
      query: () => ({
        url: "/transactions?sortOrder=ASC",
        method: "GET",
      }),
      providesTags: ["Transactions"],
      transformResponse: (response) => response.data,
    }),
    retriggerTransaction: builder.mutation({
      query: (callBackBody) => ({
        url: "/transactions/callback",
        method: "POST",
        body: callBackBody,
      }),
      invalidatesTags: ["Transactions"],
    }),
    remittanceTransactions: builder.query({
      query: (status) => ({
        url: `/transactions?status=${status}`,
        method: "GET",
      }),
      providesTags: ["Transactions"],
    }),
    retryRemittance: builder.mutation({
      query: (orderId) => ({
        url: `/remittance/jobs/initiate/${orderId}`,
        method: "POST",
      }),
      providesTags: ["Transactions"],
    }),
    searchPendingTransactions: builder.query({
      query: (filterTransactionRequest) => ({
        url: `/transactions/filter?${queryString.stringify(
          filterTransactionRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Transactions"],
    }),
  }),
});

export const {
  useGetAllTransactionsQuery,
  useGetTransactionQuery,
  useSearchTransactionsQuery,
  useCreateTransactionMutation,
  useUpdateTransactionMutation,
  useUpdateTransactionProcessMutation,
  useDeleteTransactionMutation,
  useGetLatestTransactionsQuery,
  useGetOlderTransactionsQuery,
  useRetriggerTransactionMutation,
  useRemittanceTransactionsQuery,
  useRetryRemittanceMutation,
  useSearchPendingTransactionsQuery,
} = transactionsApi;
