import React, { useState } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { useBulkUploadFilesMutation } from "../../services/FileUploadService"; // Adjust import path accordingly

const BulkUpload = () => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [bulkUploadFiles] = useBulkUploadFilesMutation();
  const [loading, setLoading] = useState(false); // Loading state

  const handleFileUpload = async (files) => {
    if (files.length > 4) {
      alert("You can only upload up to 4 files.");
      return;
    }

    const formData = new FormData();
    for (const file of files) {
      if (file.type !== "text/csv") {
        alert("Only CSV files are allowed.");
        return;
      }
      formData.append("files", file);
    }

    try {
      setLoading(true); // Start loading
      const response = await bulkUploadFiles(formData).unwrap();
      console.log(response);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false); // Stop loading after the request is complete
    }
  };

  return (
    <div>
      {loading ? (
        <CircularProgress /> // Show loader when uploading
      ) : (
        <Button
          className=""
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          style={{ borderRadius: "30px" }}
        >
          Bulk Upload
          <VisuallyHiddenInput
            type="file"
            onChange={(event) =>
              handleFileUpload(Array.from(event.target.files))
            }
            multiple
            accept=".csv"
          />
        </Button>
      )}
    </div>
  );
};

export default BulkUpload;
