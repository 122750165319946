import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { loginUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const BannerService = createApi({
  reducerPath: "BannerService",
  baseQuery: fetchBaseQuery({
    baseUrl: loginUrl,
    prepareHeaders: (headers) => {
      let clientToken = sessionStorage.getItem("clientToken");
      headers.append("Authorization", `Bearer ${clientToken}`);
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Banner"],
  endpoints: (builder) => ({
    addBanner: builder.mutation({
      query: (addBannerRequest) => ({
        url: "/banner",
        method: "POST",
        body: addBannerRequest,
      }),
      invalidatesTags: ["Banner"],
    }),
    deleteBanner: builder.mutation({
      query: (id) => ({
        url: `/banner/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Banner"],
    }),
    listBanner: builder.query({
      query: (filterBannerRequest) => ({
        url: `/banner/q?${queryString.stringify(
          filterBannerRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      invalidatesTags: ["Banner"],
    }),
  }),
});

export const {
  useAddBannerMutation,
  useDeleteBannerMutation,
  useListBannerQuery,
} = BannerService;
