// const BASEURL = "https://cat-assuring-boa.ngrok-free.app/borga";
const BASEURL = process.env.REACT_APP_BASE_URL;
const BRANCH_MECHANT = process.env.REACT_APP_MERCHANT;
const BRANCH_USER = process.env.REACT_APP_USER;
const BRANCH_NOTIFICATION = process.env.REACT_APP_NOTIFICATION;
const BRANCH_LOYALTY = process.env.REACT_APP_LOYALTY;
const BRANCH_ORDER = process.env.REACT_APP_ORDER;
const BRANCH_DISPUTE = process.env.REACT_APP_DISPUTE;
const BRANCH_REMITTANCE = process.env.REACT_APP_REMITTANCE;
const BRANCH_COUNTRY = process.env.REACT_APP_COUNTRY;
const BRANCH_PAYMENT = process.env.REACT_APP_PAYMENT;
const BRANCH_TRANSACTION = process.env.REACT_APP_BASE_TRANSACTION;
const BRANCH_FOREX = process.env.REACT_APP_FOREX;
const BRANCH_DISPUTES = process.env.REACT_APP_DISPUTES;

// const BASEURL = "https://staging.borga.money/borga"

export const merchantsUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1`;
export const merchantContactUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/merchants`;
export const merchantMediaUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/merchants`;
export const categoryUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/merchants`;
export const inventoryUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1`;
export const inventoryMediaUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const inventoryTrackerUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const warehousesUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const formQuestionsUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const dropDownSuggestionsUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const productsUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const productConfigUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const serviceTaskUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const serviceTaskConfigurationUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const serviceTaskResponseConfigurationUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const questionToOrderItemMapUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const httpHeadersUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const ordersUrl = `${BASEURL}/${BRANCH_ORDER}/api/v1`;
export const countriesUrl = `${BASEURL}/${BRANCH_COUNTRY}/api/v1`;
export const paymentUrl = `${BASEURL}/${BRANCH_PAYMENT}/api/v1/payments`;
export const paymentProviderUrl = `${BASEURL}/${BRANCH_PAYMENT}/api/v1/payment-provider`;
export const paymentTaskUrl = `${BASEURL}/${BRANCH_PAYMENT}/api/v1/payment-task`;
export const paymentMediaUrl = `${BASEURL}/${BRANCH_PAYMENT}/api/v1/payment/media`;
export const domainAccountUrl = `${BASEURL}/${BRANCH_USER}/api/v1/domain`;
export const loginUrl = `${BASEURL}/${BRANCH_USER}/api/v1/auth`;
export const rolesUrl = `${BASEURL}/${BRANCH_USER}/api/v1`;
export const mobileUsersUrl = `${BASEURL}/${BRANCH_USER}/api/v1`;
export const responseCodeMapUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1/inventory`;
export const transactionsActivitiesUrl = `${BASEURL}/${BRANCH_TRANSACTION}/api/v1`;
export const orderActivitiesUrl = `${BASEURL}/${BRANCH_ORDER}/api/v1`;
export const remittanceActivitiesUrl = `${BASEURL}/${BRANCH_REMITTANCE}/api/v1`;
export const procurementActivitiesUrl = `${BASEURL}/${BRANCH_MECHANT}/api/v1`;
export const forexUrl = `${BASEURL}/${BRANCH_FOREX}/api/v1`;
export const forexActivityLogUrl = `${BASEURL}/${BRANCH_FOREX}/api/v1/forex`;
export const disputesActivityLogUrl = `${BASEURL}/${BRANCH_DISPUTE}/api/v1`;
export const activityLogUrl = `${BASEURL}/${BRANCH_FOREX}/api/v1`;
export const disputesUrl = `${BASEURL}/${BRANCH_DISPUTES}/api/v1`;
export const currenciesUrl = `${BASEURL}/${BRANCH_COUNTRY}/api/v1/country`;
export const transactionsUrl = `${BASEURL}/${BRANCH_TRANSACTION}/api/v1`;
export const paymentsUrl = `${BASEURL}/${BRANCH_PAYMENT}/api/v1`;
export const remittanceUrl = `${BASEURL}/${BRANCH_REMITTANCE}/api/v1/remittance`;
export const merchantPurchaseOrderUrl = `${BASEURL}/${BRANCH_ORDER}/api/v1`;
export const disputeUrl = `${BASEURL}/${BRANCH_DISPUTE}/api/v1`;
export const notificationsUrl = `${BASEURL}/${BRANCH_NOTIFICATION}/api/v1`;
export const devicesUrl = `${BASEURL}/${BRANCH_NOTIFICATION}/api/v1`;
export const kycUrl = `${BASEURL}/${BRANCH_USER}/api/v1`;
export const fileUploadUrl = `${BASEURL}/api/v1`;
// ***** loyalty api routes ********

export const LoyaltyUrl = `${BASEURL}/${BRANCH_LOYALTY}/api/v1`;
