import React, { useState } from "react";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "../components/common/CustomToolbar";
import {
  useAddBannerMutation,
  useDeleteBannerMutation,
  useListBannerQuery,
} from "../services/BannerService";
import { BannerTable } from "../data/BannerTable";
import { DataGridService } from "../core/dataGridService";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import BannerModal from "../components/modals/BannerModal";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";

const Banners = () => {
  const dataGridService = new DataGridService("Banner");
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const [bannerRequest, setBannerRequest] = useState(null);
  const [openBannerModal, setOpenBannerModal] = useState(false);
  const [createBanner, { isSuccess: isCountryCreateSuccess }] =
    useAddBannerMutation();
  const [deleteBanner, { isSuccess: isCountryDeletedSuccess }] =
    useDeleteBannerMutation();
  const { data, isLoading } = useListBannerQuery({
    page: 0,
    size: 9999999,
  });

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );

  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };

  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };

  const handleDeleteAction = async (row) => {
    setBannerRequest(row);
    setOpen(true);
  };

  const handleViewAction = (row) => {
    setType("VIEW");
    setBannerRequest(row);
    setOpenBannerModal(true);
  };

  const columns = BannerTable(handleViewAction, handleDeleteAction);

  const handleOpenBannerModal = () => {
    setType("ADD");
    setOpenBannerModal(true);
  };

  const handleCloseBannerModal = () => {
    setOpenBannerModal(false);
    setBannerRequest(null);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setOpenBannerModal(false);
    setBannerRequest(null);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setBannerRequest((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitBanner = async () => {
    await createBanner(bannerRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Country created succesfully");
          handleCloseDialog();
        } else {
          ErrorToast("Failed to create Country");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Country");
      });
  };

  const handleDeleteBanner = async () => {
    await deleteBanner(bannerRequest.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Banner deleted succesfully");
        } else {
          ErrorToast(
            "Failed to delete banner " + JSON.stringify(response.error)
          );
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete banner" + JSON.stringify(error));
      })
      .finally(() => {
        setOpen(false);
        setBannerRequest(null);
      });
  };

  return (
    <div>
      <SecuredComponent
        type="PAGE"
        requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
      >
        <Box sx={{ pt: "80px", pb: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Typography variant="h6">Banners</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "20px" }}
              onClick={handleOpenBannerModal}
            >
              Add Banner
            </Button>
          </Box>
          <BannerModal
            open={openBannerModal}
            type={type}
            banner={bannerRequest}
            handleClose={handleCloseBannerModal}
            handleFormChange={handleFormChange}
            handleSubmitCountry={handleSubmitBanner}
          />
          <CustomAlertDialog
            open={open}
            handleClose={handleCloseDialog}
            handleSubmit={handleDeleteBanner}
            title={"Delete banner"}
            subTitle={`Are you sure you want to delete ${bannerRequest?.name} ?`}
            negativeButtonText={"No"}
            positiveButtonText={"Yes"}
            icon={<FiTrash2 size={30} />}
            fullWidth={true}
          />
          <Box>
            <TableContainer component={Paper}>
              {data?.data?.data?.length === 0 ? (
                <Typography
                  variant="h6"
                  align="center"
                  sx={{ padding: "20px" }}
                >
                  No Data Available
                </Typography>
              ) : (
                <DataGrid
                  autoHeight
                  sx={{
                    boxShadow: 2,
                    border: 2,
                    borderColor: "gray",
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                  }}
                  filterMode="server"
                  paginationMode="server"
                  sortingMode="server"
                  loading={isLoading}
                  rowCount={data?.data?.data?.length ?? 0}
                  columns={columns}
                  rows={data?.data?.data || []}
                  getRowId={(row) => row.id}
                  disableColumnSelector
                  disableDensitySelector
                  components={{
                    Toolbar: () => <CustomToolbar data={data} />,
                  }}
                  paginationModel={paginationModel}
                  filterModel={filterModel}
                  onPaginationModelChange={handlePaginationModelChange}
                  onFilterModelChange={handleFilterModelChange}
                />
              )}
            </TableContainer>
          </Box>
        </Box>
      </SecuredComponent>
    </div>
  );
};

export default Banners;
